<template>
  <div class="collection-push-desktop-side flex justify-center">
    <div class="grid-container">
      <div
        class="col-span-6 lg:col-span-4 collection-push-desktop-side__media"
        :class="{
          'lg:order-2 lg:col-end-13': isReversed
        }"
      >
        <LoadWhenVisible>
          <VaimoBanner
            v-bind="visualData"
            :ratio="isDesktop ? '5:6' : visualData.ratio"
            parallax
            class="collection-push-desktop-side__banner"
          />
          <VaimoButtons
            v-if="isDesktop"
            block
            :section-data="sectionData"
            class="collection-push-desktop-side__buttons text-center mt-sm"
          />
        </LoadWhenVisible>
      </div>
      <div
        class="col-span-6 lg:col-span-7 col-content collection-push-desktop-side__products-block"
        :class="{
          'lg:order-1 lg:col-end-13': !isReversed
        }"
      >
        <div class="lg:col-span-6">
          <VaimoHeading
            v-if="headingData.heading"
            heading-level="h3"
            heading-style="emphasized"
            :heading="headingData.heading"
            :heading-link="headingData.headingLink"
            class="collection-push-desktop-side__heading s:text-center s:mb-xs"
          />
          <VaimoTruncatedText
            v-if="headingData.description"
            :text="headingData.description"
            truncate="by characters"
            :characters="145"
            class="collection-push-desktop-side__description s:text-center m:mb-sm"
          />
          <VaimoButtons
            v-if="!isDesktop && isButtonsExist"
            block
            :section-data="sectionData"
            class="collection-push-desktop-side__buttons text-center mt-sm s:mb-md"
          />
        </div>
        <LoadWhenVisible class="col-content lg:col-span-7">
          <VaimoProducts
            :display="productsData.layout"
            :wrapper-props="getWrapperProps"
            :sku-list="productsData.listOfSkus"
            :alt-visuals="productsData.altVisuals"
            loading-type="spinner"
            class="collection-push-desktop-side__slider"
            unique-key="collection-push-desktop-side-slider"
          />
        </LoadWhenVisible>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useScreenSize } from '~/diptyqueTheme/composable';
import { useFlexibleSection } from '~/diptyqueTheme/composable/useFlexibleSection';
import { VisualPosition } from '~/diptyqueTheme/types/contentful';

export default defineComponent({
  name: 'DesktopSide',
  components: {
    LoadWhenVisible,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    productsData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { visualData, headingData } = useFlexibleSection(props.sectionData);
    const { isDesktop } = useScreenSize();
    const isButtonsExist = computed(
      () => props.sectionData?.buttonCollection?.items?.length
    );

    const isReversed = computed(
      () => isDesktop.value && visualData.value.posD === VisualPosition.RIGHT
    );

    const getWrapperProps = computed(() => {
      const breakpoints = [
        {
          maxWidth: 1530,
          maxItemsVisible: 3
        },
        {
          maxWidth: 1180,
          maxItemsVisible: 2
        },
        {
          maxWidth: 1023,
          maxItemsVisible: 3
        },
        {
          maxWidth: 530,
          maxItemsVisible: 2
        },
        {
          maxWidth: 360,
          maxItemsVisible: 1
        }
      ];

      return {
        slider: {
          desktopShift: 25,
          desktopQuantityVisible: 4,
          variableWidth: true,
          desktopCenterMode: false,
          responsive: breakpoints.map(({ maxWidth, maxItemsVisible }) => ({
            breakpoint: maxWidth,
            settings: {
              slidesToShow: maxItemsVisible,
              slidesToScroll: 2
            }
          }))
        }
      };
    });

    return {
      isReversed,
      visualData,
      isButtonsExist,
      isDesktop,
      getWrapperProps,
      headingData
    };
  }
});
</script>

<style lang="scss" scoped>
.collection-push-desktop-side {
  margin-block: 10px 90px;

  &__products-block {
    @include for-desktop {
      display: grid;
      column-gap: var(--spacer-md);
      grid-template-columns: repeat(7, 1fr);
      height: fit-content;
    }
  }

  &__media {
    @include for-screen-s {
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }

  &__banner {
    @include for-screen-s {
      margin-block-end: 20px;
    }
  }

  &__heading {
    ::v-deep {
      .vaimo-heading__title {
        @include for-screen-s {
          padding: 0 var(--spacer-md);
        }
      }
    }
  }

  &__description {
    font-size: var(--font-size--lg);
    font-weight: var(--font-weight--light);
    margin: 0 auto;
  }
  &__slider {
    ::v-deep {
      .vaimo-slider__slider {
        padding: 0;
      }
      .vaimo-slider {
        margin-top: 20px;
        .slick-slider {
          .slick-slide {
            width: 190px;
            min-width: 190px;
            margin: 0 20px 0 0;

            @include for-screen-s {
              width: 150px;
              min-width: 150px;
            }
            .sf-product-card {
              padding: 0;
            }
          }
        }
      }
      .vaimo-grid {
        @include for-screen-s {
          padding: 0;
          gap: var(--spacer-3md) var(--spacer-md);
          .sf-product-card {
            padding: 0;
          }
        }
      }
    }
    @include for-screen-m-and-l {
      .slick-list {
        margin-left: -10px;
      }
    }
  }
  .col-content {
    min-width: 0;
    width: 100%;
  }
}
</style>
